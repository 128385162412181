<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn && !dialog"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getLocalDocument" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>
				
				<v-col cols="auto" class="text-right py-0 mb-1">
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverNew? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverNew = !hoverNew"
									@mouseleave="hoverNew = !hoverNew"
									@click="newItem"
								>mdi-plus-circle</v-icon>
							</template>
							<small>New</small>
						</v-tooltip>
					</template>
					
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverRefresh = !hoverRefresh"
									@mouseleave="hoverRefresh = !hoverRefresh"
									@click="getLocalDocument"
								>mdi-refresh-circle</v-icon>
							</template>
							<small>Refresh</small>
						</v-tooltip>
					</template>
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0 pr-1">
			<v-data-table
				:dense="$vuetify.breakpoint.lgAndDown"
				fixed-header
				:headers="headers"
				:height="tableHeight"
				:items="localDocument"
				:loading="dataTableLoading"
				loading-text="Loading... Please wait"
				no-data-text="No records found."
				:options.sync="tableOptions"
				:page="tableOptions.page"
				:server-items-length="tableTotal"
				ref="dataTableLocalDocument"
				@update:items-per-page="tableResize; getLocalDocument();"
				@update:page="getLocalDocument"
				@update:sort-desc="getLocalDocument"
			>
				<template v-slot:header.DOC_NAME="{ header }">
					{{ header.text }}<br/>DOCUMENT NUMBER
				</template>
				
				<template v-slot:item.DOC_NAME="{ item }" >
					<div style="min-height:20px;">{{ item.DOC_NAME }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.DOC_NUMBER }}</div>
				</template>
				
				<template v-slot:item.status="{ item }" >
					<v-chip v-if="itemStatus(item.DATE_VALID) < 1" color="error" dark small>EXPIRED</v-chip>
					<v-chip v-else-if="itemStatus(item.DATE_VALID) < 90" color="warning" dark small>FOR RENEWAL</v-chip>
					<v-chip v-else color="success" dark small>VALID</v-chip>
				</template>
				
				<template v-slot:item.action="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="success" @click="editItem(item)">mdi-pencil</v-icon>
						</template>
						<small>Edit</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="error" @click="dialogDelete = true;deleteIndex = item">mdi-delete</v-icon>
						</template>
						<small>Delete</small>
					</v-tooltip>
				</template>
			</v-data-table>
			
			<v-dialog
				v-model="dialog"
				:fullscreen="$vuetify.breakpoint.xsOnly && !dialogLoading"
				max-width="650"
				:persistent="dialogLoading"
				scrollable
				@click:outside="resetFormValidation();"
				@keydown.enter="saveItem"
			>
				<v-card v-if="!dialogLoading">
					
					<v-card-title class="headline primary white--text" primary-title>
						{{ dialogTitle }}
						
						<v-spacer></v-spacer>
							
						<v-btn icon
							@click="$refs.form.resetValidation(); dialog = false;"
							class="white--text"
						>
							<v-icon small dark>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					
					<v-card-text>
						<v-form
							ref="form"
							v-model="validForm"
							lazy-validation
						>
							<v-row dense no-gutters>
								<v-col cols="12">
									<v-alert
										dense
										outlined
										border="left"
										:value="errorOn && dialog"
										:type="errorType"
										class="mt-2 py-1"
									>
										{{ errorMessage }}
									</v-alert>
								</v-col>
								
								<v-col cols="12">
									<v-select
										v-model="editedItem.DOC_ID"
										:items="localDocOption"
										label="Document Name*"
										item-text="DOC_NAME"
										item-value="DOC_ID"
										:rules= "rulesLocalDocumentName"
										validate-on-blur
									></v-select>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										v-model="editedItem.DOC_NUMBER"
										label="Document Number*"
										:rules= "rulesLocalDocumentNumber"
										validate-on-blur
										@blur="editedItem.DOC_NUMBER = trimBlur(editedItem.DOC_NUMBER)"
										class="uppercase-input"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										v-model="editedItem.PLACE_ISSUED"
										label="Place of Issued"
										hint="City, Province"
										:rules="rulesPlaceIssued"
										validate-on-blur
										@blur="editedItem.PLACE_ISSUED = trimBlur(editedItem.PLACE_ISSUED)"
										class="uppercase-input"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Date Issued*"
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DateIssuedType"
										v-model="editedItem.DATE_ISSUED"
										:rules="rulesDateIssued"
										validate-on-blur
										@focus="DateIssuedType = 'date'"
										@blur="DateIssuedType = (isValidDate(editedItem.DATE_ISSUED))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Date Valid*"
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DateValidType"
										v-model="editedItem.DATE_VALID"
										:rules="rulesDateValid"
										validate-on-blur
										@focus="DateValidType = 'date'"
										@blur="DateValidType = (isValidDate(editedItem.DATE_VALID))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-form>
						
					</v-card-text>
					
					<v-card-actions>
						<small class="pl-4 font-weight-light">Note: Field with asterisk(*) is required.</small>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="$refs.form.resetValidation(); dialog = false;">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
					</v-card-actions>
				</v-card>
				
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			
			<v-dialog
				v-model="dialogDelete"
				max-width="355"
				:persistent="dialogLoading"
			>
			
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Deleting...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
				
				<v-card v-else-if="!dialogLoading">
					<v-card-title class="subtitle-1 warning white--text">CONFIRM DELETE</v-card-title>
					
					<v-card-text class="subtitle-1 font-weight-medium pt-5">Are you sure you want to delete this item?</v-card-text>
					
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialogDelete = false">NO</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItem()">YES</v-btn>
					</v-card-actions>
				</v-card>
				
			</v-dialog>
		</v-card-text>
	</v-card>
	
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import { mapGetters } from 'vuex';

var timeoutData			= null;
var timeoutLoading		= null;
var timeoutTableResize	= null;
var timeoutDropdown		= null;
var timeoutDialogClose	= null;
var timeoutWindowResize	= null;

export default {
	data () {
		return {
			tableTotal			: 0,
			tableOptions		: {	page: 1, itemsPerPage: 10, sortBy: ['DOC_NAME'], sortDesc: [false] },
			
			hoverNew			: false,
			hoverRefresh		: false,
			
			DateIssuedType		: 'text',
			DateValidType		: 'text',
			
			localDocOption		: [],
			localDocument		: [],
			
			isLoaded			: false,
			
			dialog				: false,
			validForm			: true,
			dialogDelete		: false,
			dialogLoading		: false,
			dataTableLoading	: true,
			isLoadedOptions		: false,
			
			tableHeight			: 'undefined',
			
			deleteIndex			: [],
			editedIndex			: -1,
			editedItem: {
				ID				: '',
				DOC_ID			: '',
				DOC_NAME		: '',
				DOC_NUMBER		: '',
				PLACE_ISSUED	: '',
				DATE_ISSUED		: '',
				DATE_VALID		: '',
			},
			defaultItem: {
				ID				: '',
				DOC_ID			: '',
				DOC_NAME		: '',
				DOC_NUMBER		: '',
				PLACE_ISSUED	: '',
				DATE_ISSUED		: '',
				DATE_VALID		: '',
			},
			headers: [
				{ text: 'DOCUMENT NAME',	value: 'DOC_NAME',		align: 'start'	},
				{ text: 'DOCUMENT NUMBER',	value: 'DOC_NUMBER',	align: ' d-none',	sortable: false },
				{ text: 'PLACE OF ISSUED',	value: 'PLACE_ISSUED',	align: 'start'	},
				{ text: 'DATE ISSUED',		value: 'DATE_ISSUED',	align: 'center' },
				{ text: 'DATE VALID',		value: 'DATE_VALID',	align: 'center' },
				{ text: 'STATUS',			value: 'status',		align: 'center',	sortable: false },
				{ text: 'ACTIONS',			value: 'action',		align: 'center',	sortable: false },
			],
			rulesLocalDocumentName: [
				v => !!v || 'Document Name is required',
			],
			rulesLocalDocumentNumber: [
				v => !!v || 'Document Number is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
				v => !/[^a-zA-ZñÑ0-9-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesPlaceIssued: [
				v => ((!!v && this.stringLength(v) >= 3) || (this.stringLength(v) == 0)) || 'Must be atleast 3 characters',
				v => ((!!v && this.stringLength(v) <= 100) || (this.stringLength(v) == 0)) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ0-9-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesDateIssued:[
				v => !!v || 'Date Issued is required',
				(v) => this.compareDate(v, this.editedItem.DATE_VALID) || 'Must be less than date valid',
			],
			rulesDateValid:[
				v => !!v || 'Date Valid is required',
				(v) => this.compareDate(this.editedItem.DATE_ISSUED, v) || 'Must be greater than date issued',
			]
		}
	},
	methods: {
		getLocalDocument () {
			if(this.dataTableLoading && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();

			this.$store.dispatch(CLEAR_ALERT);
			this.dataTableLoading = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
			
			this.$http.get('localdocs', { params:{ options: this.tableOptions } }, { cancelToken: source.token })
			.then((resp) => {
				this.localDocument = resp.data.data;
				this.tableTotal = resp.data.rows;
				this.isLoaded = true;
				this.dropdown();
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getEducation();
				}, 60000);
			})
			.finally(() => {
				this.dataTableLoading = false;
				this.tableResize();
			});
		},
		newItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
			
			setTimeout(() => {
				if(typeof this.$refs.form !== 'undefined')this.$refs.form.resetValidation();
			}, 50);
		},
		editItem: function(item){
			if(this.isValidDate(item.DATE_ISSUED)){
				this.DateIssuedType = 'date';
			}else{
				item.DATE_ISSUED = '';
				this.DateIssuedType = 'text';
			}
			
			if(this.isValidDate(item.DATE_VALID)){
				this.DateValidType = 'date';
			}else{
				item.DATE_VALID = '';
				this.DateValidType = 'text';
			}
			
			if(item.DOC_ID != 0)this.localDocOption.push( {'DOC_ID': item.DOC_ID, 'DOC_NAME': item.DOC_NAME} );
			
			this.editedIndex = this.localDocument.indexOf(item);
			this.editedItem = Object.assign({}, item);
			
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
		},
		deleteItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialogLoading = true;
			this.$http.delete('localdocs', {
				params: {
					ID: this.deleteIndex.ID
				}
			})
			.then(() => {
				const index = this.localDocument.indexOf(this.deleteIndex);
				this.localDocument.splice(index, 1);
				this.tableTotal--;
				this.tableResize();
			})
			.finally(() => {
				this.dialogDelete = false;
				clearTimeout(timeoutLoading);
				timeoutLoading = setTimeout(() => {
					this.dialogLoading = false;
				}, 300);
			});
		},
		saveItem: function(){
			if(this.$refs.form.validate()){
				this.$store.dispatch(CLEAR_ALERT);
				this.dialogLoading = true;
				
				if(this.editedIndex > -1){//Edit Item
					this.$http.put('localdocs', {
						forms: this.editedItem
					}).then(() => {
						Object.assign(this.localDocument[this.editedIndex], this.editedItem);
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}else{//New Item
					//add DOC_NAME value to editedItem object
					this.editedItem.DOC_NAME = this.localDocOption.find(({ DOC_ID }) => DOC_ID === this.editedItem.DOC_ID).DOC_NAME;
				
					this.$http.post('localdocs', {
						forms: this.editedItem
					}).then((response) => {
						this.editedItem.ID = response.data.ID;
						
						this.tableTotal++;
						this.localDocument.unshift(this.editedItem);
						
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}
			}
		},
		tableResize(){
			clearTimeout(timeoutTableResize);
			timeoutTableResize = setTimeout(() => {
				if(typeof this.$refs.dataTableLocalDocument !== 'undefined'){
					const windowWidth = this.$refs.dataTableLocalDocument.$parent.$el.clientWidth;
					const tableWidth = this.$refs.dataTableLocalDocument.$el.children[0].children[0].clientWidth;
					const scrollX = ( tableWidth > windowWidth)? 20 : 0;
					
					const tbodyHeight= this.$refs.dataTableLocalDocument.$el.children[0].children[0].clientHeight;
					this.tableHeight = ( (this.userFormHeight - (40 + 65) ) > tbodyHeight )? tbodyHeight : this.userFormHeight - (40 + 65);
					this.tableHeight += scrollX;
				}
			}, 50);
		},
		dropdown(){
			if(!this.isLoadedOptions){
				clearTimeout(timeoutDropdown);
				this.$http.get('dropdown/localDocument').then((resp) => {
					this.localDocOption = resp.data;
				})
				.then(() => {
					this.isLoadedOptions = true;
				})
				.catch(() => {//retry
					timeoutDropdown = setTimeout(() => {
						this.dropdown();
					}, 10000)
				});
			}
		},
		resetFormValidation(){
			if(!this.dialogLoading)this.$refs.form.resetValidation();
		},
		itemStatus: function(endDate){
			var dt1 = new Date();
			var dt2 = new Date(endDate);
			return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
		},
		compareDate(d1 , d2, opr){
			if( d1 == '' || d2 == '' || typeof d1 === 'undefined' || typeof d2 === 'undefined' ){
				return true;
			}else{
				var x = new Date(d1);
				var y = new Date(d2);
				return ( opr == '=' )? y >= x : y > x;
			}
		},
		trimBlur(text){
			if(typeof text !== 'undefined')return text.toUpperCase().replace('ñ', 'Ñ').replace(/ +/g, ' ').trimStart().trim();
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		closeDialog () {
			this.dialog = false
			clearTimeout(timeoutDialogClose);
			timeoutDialogClose = setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				
				this.DateIssuedType = this.DateValidType = 'text';
			}, 50);
		},
		isValidDate(dateString) {
			if(dateString == null)return false;
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'windowSize', 'errorOn', 'errorType', 'errorMessage']),
		dialogTitle () {
			return this.editedIndex === -1 ? 'New Local Document' : 'Edit Local Document'
		},
	},
	watch: {
		dialog (val) {
			val || this.closeDialog()
		},
		windowSize(newVal, oldVal){
			if( newVal != oldVal ){
				clearTimeout(timeoutWindowResize);
				timeoutWindowResize = setTimeout(() => {
					this.tableResize();
				}, 600)
			}
		},
	},
	created () {
		this.getLocalDocument();
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timeoutLoading);
		clearTimeout(timeoutDropdown);
		clearTimeout(timeoutTableResize);
		clearTimeout(timeoutDialogClose);
		clearTimeout(timeoutWindowResize);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>